import {
  getClickEvent,
  loadProductCategory
} from '../gtm-events';
import { SIMILAR_PRODUCT } from 'src/common/constants';

export default {
  methods: {
    async onCarouselItemClick ({ product: item, index }, eventName) {
      const product = this.recommendations.find(
        recommendation => recommendation.id === item.id
      );
      this.$gtm.trackEvent(
        getClickEvent(eventName, {
          position: ++index,
          category: await loadProductCategory(
            { dispatch: this.$store.dispatch },
            product
          ),
          list: this.getCurrentProduct && this.getCurrentProduct.name,
          ...product
        })
      );
    }
  }
};
