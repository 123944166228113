import config from 'config'
import cloneDeep from 'lodash-es/cloneDeep'

function getProductGtmData (product) {
  const {
    position,
    name,
    price,
    sku: id,
    list,
    category
  } = product;

  let productGtmData = {
    position,
    id,
    name,
    price
  };

  if (list) {
    productGtmData['list'] = list
  }

  if (category) {
    productGtmData['category'] = category.name
  }

  return productGtmData;
}
function getImpressionGtmData (product) {
  const {
    position,
    name,
    price,
    id,
    list,
    category
  } = product;

  let productGtmData = {
    position,
    id,
    name,
    price
  };

  if (id) {
    productGtmData['id'] = id
  }

  if (list) {
    productGtmData['list'] = list
  }

  if (category) {
    productGtmData['category'] = category
  }

  return productGtmData;
}

export async function loadProductCategory ({ dispatch }, product: any = {}) {
  let category;
  if (product && product.category_ids) {
    const categoryFilters = Object.assign(
      { 'id': [...product.category_ids] },
      cloneDeep(config.entities.category.breadcrumbFilterFields)
    );
    const categories = await dispatch('category-next/loadCategories', {
      filters: categoryFilters,
      reloadAll: Object.keys(config.entities.category.breadcrumbFilterFields).length > 0 }, { root: true }
    );
    category = categories.sort((a, b) => (a.level > b.level) ? -1 : 1)[0];
  }
  return category;
}

export const getClickEvent = (eventName, product) => ({
  event: eventName,
  eventAction: eventName,
  [eventName]: getProductGtmData(product)
});

export const getImpressionEvent = (eventName, products) => ({
  event: eventName,
  eventAction: eventName,
  [eventName]: products.map(getImpressionGtmData)
})
