<template>
  <section v-if="products && products.length"
           class="homepage-product-carousel-container"
           :style="backgroundStyle"
  >
    <MProductCarousel
      ref="productCarouselRef"
      :title="title"
      :products="products"
      :block-id="blockId"
      :view-more="{
        isVisible: true,
        ctaLink: lastItemCTA,
        image: lastItemImage,
        text: lastItemText,
      }"
      :track-sb-event="true"
      :observe-individual-item="observeIndividualItem"
      :per-view="Number(perView)"
      :open-new-tab="openNewTab"
      :product-carousel-banner="productCarouselBanner"
    />
  </section>
</template>
<script>
import SetupGtmEvents from 'src/modules/aws-personalize/mixins/SetupGtmEvents';

const MProductCarousel = () => import(/* webpackChunkName: "m-product-carousel" */'common/components/molecules/m-product-carousel')

export default {
  name: 'HomepageProductCarousel',
  components: {
    MProductCarousel
  },
  mixins: [SetupGtmEvents],
  props: {
    blockId: {
      type: String,
      default: ''
    },
    categoryId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    lastItemCTA: {
      type: Object,
      default: () => {}
    },
    lastItemText: {
      type: String,
      default: ''
    },
    lastItemImage: {
      type: Object,
      default: () => {}
    },
    observeIndividualItem: {
      type: Boolean,
      default: false
    },
    perView: {
      type: String,
      default: '4'
    },
    openNewTab: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    productCarouselBanner: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    products: []
  }),
  async created () {
    const response = await this.$store.dispatch('catalog-es/getProductsByCategoryIds', {
      pageSize: 10,
      categoryIds: this.categoryId
    });
    if (response) {
      this.products = response?.items;
    }
  },
  computed: {
    backgroundStyle () {
      if (this.backgroundColor) {
        return { backgroundColor: this.backgroundColor };
      } else {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.homepage-product-carousel-container {
  padding: 0 20px;
  background: var(--white);

  ::v-deep .m-product-carousel {
    margin-top: 0;
    @include for-mobile {
      padding-bottom: 0;
      .product-carousel__title {
        margin-bottom: 10px;
        font-size: 28px;
      }

      .sf-carousel__controls {
        top: -10px;
      }
    }

    .sf-product-card {
      .sf-product-card__image-wrapper {
        margin-bottom: 15px;
      }
      .sf-product-card__price {
        display: flex;
        flex-direction: row-reverse;
        font-weight: 500;
        font-size: 13px;
        margin: 12px 0;
        margin-bottom: 6px;
        .sf-price__value--old {
          color: var(--neutral-500);
          margin-left: var(--spacing-lg);
          font-weight: 400;
        }
        .sf-price__value--special {
          text-decoration: none;
          color: var(--heart-red-base);
          font-weight: 600;
          margin-left: 0;
        }
      }
      .m-product-options-configurable {
        padding-bottom: 0;
        .product__colors {
          margin-left: 4px;
          .a-product-select-color {
            margin: 0 var(--spacing-xl) 0 0;
          }
        }
      }
    }
  }
}
</style>
